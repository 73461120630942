<template>
  <div>
    <v-container fluid class="px-0">
      <v-card outlined elevation="3">
        <v-card-text>
          <v-data-table
            class="black--text"
            :headers="headers"
            :search="search"
            :items="dataTipeGudang"
            :loading="loading"
            dense
          >
            <template v-slot:top>
              <v-toolbar flat dense class="mb-3">
                <v-row>
                  <v-col md="8">
                    <v-text-field
                      outlined
                      dense
                      v-model="search"
                      append-icon="mdi-magnify"
                      autocomple="off"
                      placeholder="Search..."
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" align="right">
                    <v-btn
                      v-if="modify"
                      color="#61B15A"
                      class="text-subtitle-2 white--text"
                      style="text-transform: unset !important"
                      @click="checkType(0)"
                    >
                      <v-icon left>mdi-plus-circle</v-icon>
                      Tambah Tipe Gudang
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>

            <template v-if="modify" v-slot:item.actions="{ item }">
              <v-btn
                color="#FFA900"
                small
                class="text-caption white--text mr-2"
                style="text-transform: unset !important"
                @click.prevent="checkType(item.id)"
              >
                Edit
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

    <olah-data
      :olahDataDialog="olahDataDialog"
      :olahDataTitle="olahDataTitle"
      :olahDataBtn="olahDataBtn"
      :olahData="olahData"
      :typeAction="typeAction"
      @closeOlahDataDialog="closeOlahDataDialog"
      @reloadData="reloadData"
    />

    <v-dialog v-model="progress" width="500">
      <v-card>
        <v-card-text class="pt-5">
          <v-progress-linear indeterminate color="#FFA900"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="popDialog" width="430">
      <v-card color="white" rounded>
        <v-card-title class="text-h5 black--text">
          {{ popTitle }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-green darken-1"
            class="white--text"
            @click="closePopDialog"
            >Tutup</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import olahData from "../tipeGudang/olahData.vue";

export default {
  components: { olahData },
  props: { modify: Boolean },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      {
        text: "Kode Tipe Gudang",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "kode",
      },
      {
        text: "Tipe Gudang",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "name",
      },
      {
        text: "Action",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "actions",
      },
    ],
    dataTipeGudang: [],
    olahDataDialog: false,
    typeAction: "",
    olahDataTitle: "",
    olahDataBtn: "",
    olahData: {},
    progress: false,
    popDialog: false,
    popTitle: "",
  }),

  methods: {
    ...mapActions({
      refreshAction: "auth/refresh",
    }),

    getData() {
      this.loading = true;
      axios
        .get("v1/user/gudang_type/list?limit=200")
        .then((response) => {
          this.loading = false;
          this.dataTipeGudang = response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.refreshAction(localStorage.getItem("refresh"));
              this.getData();
            }
            console.clear();
          }
        });
    },
    getDataById(id) {
      axios
        .get("v1/user/gudang_type?id=" + id)
        .then((response) => {
          this.progress = false;
          this.olahData = response.data.data;
          this.typeAction = "edit";
          this.olahDataTitle = "Edit Gudang";
          this.olahDataBtn = "Edit & Simpan";
          this.olahDataDialog = true;
        })
        .catch((error) => {
          this.progress = false;
          if (error.response) {
            if (error.response.status === 401) {
              this.refreshAction(localStorage.getItem("refresh"));
              this.getData();
            }
            console.clear();
          }
        });
    },
    checkType(value) {
      if (value === 0) {
        this.typeAction = "new";
        this.olahDataTitle = "Tambah Gudang";
        this.olahDataBtn = "Tambahkan";
        this.olahDataDialog = true;
      } else {
        this.progress = true;
        this.getDataById(value);
      }
    },
    reloadData(){
      if(this.typeAction === "edit"){
        this.popTitle = "Data Gudang berhasil diperbaharui";
        this.popDialog = true;
      }else if(this.typeAction === "new"){
        this.popTitle = "Data Gudang berhasil ditambahkan";
        this.popDialog = true;
      }
    },
    closeOlahDataDialog() {
      this.olahData = {};
      this.dataTipeGudang = [];
      this.getData();
      this.olahDataDialog = false;
    },
    closePopDialog() {
      this.popTitle = "";
      this.popColor = "";
      this.popDialog = false;
    },
  },

  created() {
    this.getData();
  },
};
</script>