<template>
  <div>
    <v-container fluid class="px-0">
      <v-card outlined elevation="3">
        <v-card-text>
          <v-data-table
            class="black--text"
            :headers="headers"
            :search="search"
            :items="dataTipeBox"
            :loading="loading"
            dense
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from "vuex";

export default {
  data: () => ({
    search: "",
    loading: false,
    headers: [
      {
        text: "Kode Box",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "kode",
      },
      {
        text: "Nama Box",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "name",
      },
    ],
    dataTipeBox: [],
  }),

  methods: {
    ...mapActions({
      refreshAction: "auth/refresh",
    }),

    getData() {
      this.loading = true;
      axios.get("v1/admin/box_type/list?limit=200").then((response) => {
        this.loading = false;
        this.dataTipeBox = response.data.data
      }).catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.refreshAction(localStorage.getItem("refresh"));
              this.getData();
            }
            console.clear();
          }
        });
    },
  },

  created() {
    this.getData();
  },
};
</script>